<template>
  <div>
    <b-card title="Thông tin merchant">
      <b-card-text class="d-flex">
        <b-form-group
          label="ID"
          label-for="mc-id"
          class="mr-1"
        >
          <b-form-input
            id="mc-id"
            v-model="merchant.hashedId"
            disabled
          />
        </b-form-group>
        <b-form-group
          label="Tên khách hàng"
          label-for="mc-name"
          class="mr-1"
        >
          <b-form-input
            id="mc-name"
            v-model="merchant.name"
            disabled
          />
        </b-form-group>
        <b-form-group
          label="Email"
          label-for="mc-email"
          class="mr-1"
        >
          <b-form-input
            id="mc-email"
            v-model="merchant.email"
            disabled
          />
        </b-form-group>
        <b-form-group
          label="Số điện thoại"
          label-for="mc-phone"
          class="mr-1"
        >
          <b-form-input
            id="mc-phone"
            v-model="merchant.phone"
            disabled
          />
        </b-form-group>
        <b-form-group
          label="Ngày tạo"
          label-for="mc-date"
          class="mr-1"
        >
          <b-form-input
            id="mc-date"
            v-model="mcCreatedAt"
            disabled
          />
        </b-form-group>
        <b-form-group
          label="Thuộc đại lý"
          label-for="mc-parent"
          class="mr-1"
        >
          <b-form-input
            id="mc-parent"
            disabled
          />
        </b-form-group>
        <b-form-group
          label="Trạng thái"
          label-for="mc-status"
          class="mr-1"
        >
          <v-select
            id="mc-status"
            v-model="userStatus"
            placeholder="Trạng thái"
            label="text"
            :options="userStatusOptions"
            :reduce="status => status.value"
            :clearable="false"
            :searchable="false"
          />
        </b-form-group>
      </b-card-text>
    </b-card>

    <b-row>
      <b-col cols="6">
        <b-card title="Cài đặt mức phí kênh QR bank">
          <b-card-text class="d-flex align-items-end">
            <b-form-group
              label="QR bank"
              label-for="fee-qr"
            >
              <b-input-group
                append="%"
              >
                <b-form-input
                  id="fee-qr"
                  v-model="payInConfig.percent_fee"
                  placeholder="nhập mức phí"
                />
              </b-input-group>
            </b-form-group>
            <b-form-group class="pl-5">
              <b-form-checkbox
                v-model="payInConfig.isActive"
                value="1"
                unchecked-value="0"
                name="check-button"
                switch
                inline
              >
                Kích hoạt
              </b-form-checkbox>
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col cols="6">
        <b-card title="Cài đặt mức phí Payout">
          <b-card-text class="d-flex align-items-end">
            <b-form-group
              label="QR bank"
              label-for="fee-qr"
            >
              <b-input-group
                append="%"
              >
                <b-form-input
                  id="fee-qr"
                  v-model="payOutConfig.percent_fee"
                  placeholder="nhập mức phí"
                />
              </b-input-group>
            </b-form-group>
            <b-form-group class="pl-5">
              <b-form-checkbox
                v-model="payOutConfig.isActive"
                value="1"
                unchecked-value="0"
                name="check-button"
                switch
                inline
              >
                Kích hoạt
              </b-form-checkbox>
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <b-card title="Cài đặt mức phí kênh thẻ cào">
          <b-row align-v="end">
            <b-col>
              <b-form-group
                label="Mức phí viettel"
                label-for="fee-vt"
              >
                <b-input-group
                  append="%"
                >
                  <b-form-input
                    id="fee-vt"
                    v-model="viettelConfig.percent_fee"
                    placeholder="nhập mức phí"
                  />
                </b-input-group>
              </b-form-group>
              <b-form-group>
                <b-form-checkbox
                  v-model="viettelConfig.isActive"
                  value="1"
                  unchecked-value="0"
                  name="check-button"
                  switch
                  inline
                >
                  Kích hoạt
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                label="Mức phí mobifone"
                label-for="fee-vt"
              >
                <b-input-group
                  append="%"
                >
                  <b-form-input
                    id="fee-vt"
                    v-model="mobiConfig.percent_fee"
                    placeholder="nhập mức phí"
                  />
                </b-input-group>
              </b-form-group>
              <b-form-group>
                <b-form-checkbox
                  v-model="mobiConfig.isActive"
                  value="1"
                  unchecked-value="0"
                  name="check-button"
                  switch
                  inline
                >
                  Kích hoạt
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                label="Mức phí vinaphone"
                label-for="fee-vt"
              >
                <b-input-group
                  append="%"
                >
                  <b-form-input
                    id="fee-vt"
                    v-model="vinaConfig.percent_fee"
                    placeholder="nhập mức phí"
                  />
                </b-input-group>
              </b-form-group>
              <b-form-group>
                <b-form-checkbox
                  v-model="vinaConfig.isActive"
                  value="1"
                  unchecked-value="0"
                  name="check-button"
                  switch
                  inline
                >
                  Kích hoạt
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                label="Mức phí vietnamobile"
                label-for="fee-vt"
              >
                <b-input-group
                  append="%"
                >
                  <b-form-input
                    id="fee-vt"
                    v-model="vietnamobileConfig.percent_fee"
                    placeholder="nhập mức phí"
                  />
                </b-input-group>
              </b-form-group>
              <b-form-group>
                <b-form-checkbox
                  v-model="vietnamobileConfig.isActive"
                  value="1"
                  unchecked-value="0"
                  name="check-button"
                  switch
                  inline
                >
                  Kích hoạt
                </b-form-checkbox>
              </b-form-group>
            </b-col>

          </b-row>
        </b-card>
      </b-col>

      <b-col>
        <b-card
          title="Cài đặt riêng"
          style="min-height: 197px;"
        >
          <b-row>
            <b-col>
              <b-button
                variant="outline-primary"
              >
                QR Bank
              </b-button>
              <b-form-group class="my-2">
                <b-form-checkbox
                  checked="true"
                  name="check-button"
                  switch
                  inline
                >
                  Kích hoạt
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col>
              <b-button
                variant="outline-primary"
              >
                Thẻ cào
              </b-button>
              <b-form-group class="my-2">
                <b-form-checkbox
                  checked="true"
                  name="check-button"
                  switch
                  inline
                >
                  Kích hoạt
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col>
              <b-button
                variant="outline-primary"
              >
                Payout
              </b-button>
              <b-form-group class="my-2">
                <b-form-checkbox
                  checked="true"
                  name="check-button"
                  switch
                  inline
                >
                  Kích hoạt
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="6">
        <b-card title="Cài đặt mức phí kênh 1vnpay">
          <b-row align-v="end">
            <b-col>
              <b-form-group
                label="Mức phí zalo qr"
                label-for="fee-vt"
              >
                <b-input-group
                  append="%"
                >
                  <b-form-input
                    id="fee-zalo-qr"
                    v-model="zaloQrConfig.percent_fee"
                    placeholder="nhập mức phí"
                  />
                </b-input-group>
              </b-form-group>
              <b-form-group>
                <b-form-checkbox
                  v-model="zaloQrConfig.isActive"
                  value="1"
                  unchecked-value="0"
                  name="check-button"
                  switch
                  inline
                >
                  Kích hoạt
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                label="Mức phí momo qr"
                label-for="fee-momo-qr"
              >
                <b-input-group
                  append="%"
                >
                  <b-form-input
                    id="fee-vt"
                    v-model="momoQrConfig.percent_fee"
                    placeholder="nhập mức phí"
                  />
                </b-input-group>
              </b-form-group>
              <b-form-group>
                <b-form-checkbox
                  v-model="momoQrConfig.isActive"
                  value="1"
                  unchecked-value="0"
                  name="check-button"
                  switch
                  inline
                >
                  Kích hoạt
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                label="Mức phí viettel qr"
                label-for="fee-viettel-qr"
              >
                <b-input-group
                  append="%"
                >
                  <b-form-input
                    id="fee-vt"
                    v-model="viettelQrConfig.percent_fee"
                    placeholder="nhập mức phí"
                  />
                </b-input-group>
              </b-form-group>
              <b-form-group>
                <b-form-checkbox
                  v-model="viettelQrConfig.isActive"
                  value="1"
                  unchecked-value="0"
                  name="check-button"
                  switch
                  inline
                >
                  Kích hoạt
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                label="Mức phí usdt"
                label-for="fee-usdt"
              >
                <b-input-group
                  append="%"
                >
                  <b-form-input
                    id="fee-usdt"
                    v-model="usdtConfig.percent_fee"
                    placeholder="nhập mức phí"
                  />
                </b-input-group>
              </b-form-group>
              <b-form-group>
                <b-form-checkbox
                  v-model="usdtConfig.isActive"
                  value="1"
                  unchecked-value="0"
                  name="check-button"
                  switch
                  inline
                >
                  Kích hoạt
                </b-form-checkbox>
              </b-form-group>
            </b-col>

          </b-row>
        </b-card>
      </b-col>

      <b-col cols="6">
        <b-card>
          <b-card-text class="d-flex justify-content-between">
            <h4>Cài đặt mức phí thành viên</h4>
            <b-form-group>
              <b-form-checkbox
                v-model="memberFeeConfigs.is_active"
                value="1"
                unchecked-value="0"
                name="check-button"
                switch
                inline
              >
                {{ memberFeeConfigs.is_active ? 'Bật' : 'Tắt' }}
              </b-form-checkbox>
            </b-form-group>
          </b-card-text>
          <b-row align-v="end">
            <b-col>
              <b-form-group
                label="Mức phí viettel"
                label-for="member-fee-vt"
              >
                <b-input-group
                  append="%"
                >
                  <b-form-input
                    id="member-fee-vt"
                    v-model="memberFeeConfigs.member_fee_viettel"
                    placeholder="nhập mức phí"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                label="Mức phí mobifone"
                label-for="member-fee-vt"
              >
                <b-input-group
                  append="%"
                >
                  <b-form-input
                    id="member-fee-vt"
                    v-model="memberFeeConfigs.member_fee_mobi"
                    placeholder="nhập mức phí"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                label="Mức phí vinaphone"
                label-for="member-fee-vt"
              >
                <b-input-group
                  append="%"
                >
                  <b-form-input
                    id="member-fee-vt"
                    v-model="memberFeeConfigs.member_fee_vinaphone"
                    placeholder="nhập mức phí"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                label="Mức phí vietnamobile"
                label-for="member-fee-vietnamobile"
              >
                <b-input-group
                  append="%"
                >
                  <b-form-input
                    id="member-fee-vietnamobile"
                    v-model="memberFeeConfigs.member_fee_vnm"
                    placeholder="nhập mức phí"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

          </b-row>
          <b-row align-v="end">
            <b-col cols="3">
              <b-form-group
                label="Mức phí QR Bank"
                label-for="member-fee-qr-bank"
              >
                <b-input-group
                  append="%"
                >
                  <b-form-input
                    id="member-fee-qr-bank"
                    v-model="memberFeeConfigs.member_fee_qr"
                    placeholder="nhập mức phí"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row>

      <b-col cols="6">
        <b-card>
          <b-card-text class="d-flex justify-content-between">
            <h4>Cài đặt phí rút USDT</h4>
          </b-card-text>
          <b-row align-v="end">
            <b-col>
              <b-form-group
                label="Mức phí rút USDT"
                label-for="member-fee-usdt-out"
              >
                <b-input-group
                  append="USDT"
                >
                  <b-form-input
                    id="member-fee-usdt-out"
                    v-model="merchant.usdtOutFee"
                    placeholder="nhập mức phí"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col cols="6">
        <b-card title="Giới hạn số tiền Payout">
          <b-card-text class="d-flex align-items-end">
            <b-form-group
              label="Số tiền tối đa"
              label-for="payout_limit"
              style="width: 100%"
            >
              <b-form-input
                id="payout_limit"
                v-model="merchant.payoutLimit"
              />
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>

    </b-row>

    <b-row>
      <b-col cols="6">
        <b-card title="Đối soát sai mệnh giá">
          <b-card-text class="d-flex align-items-end">
            <b-form-group
              label="Thẻ cào"
              label-for="control-qr"
            >
              <b-form-select
                id="control-qr"
                v-model="merchant.forControlCard"
                :options="controlStatusOptions"
              />
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col cols="6">
        <b-card title="IPs truy cập API">
          <b-card-text class="d-flex align-items-end">
            <b-form-group
              label="IPs (cách nhau bởi dấu phẩy, vd: 118.71.204.98,118.71.204.99)"
              label-for="ips"
              style="width: 100%"
            >
              <b-form-input
                id="ips"
                v-model="merchant.ips"
                placeholder="Nhập IPs, cách nhau bằng dấu phẩy"
              />
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-button
          variant="danger"
          @click="$router.push({name: 'merchant-management-account'})"
        >
          Quay lại
        </b-button>
      </b-col>
      <b-col class="d-flex flex-row-reverse">
        <b-button
          variant="outline-primary"
          @click="$router.push({name: 'merchant-management-account'})"
        >
          Hủy bỏ
        </b-button>
        <b-button
          variant="primary"
          class="mr-2"
          @click="handleSaveConfig"
        >
          Áp dụng
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BInputGroup,
  BFormCheckbox,
  BButton,
  BFormSelect,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { formatDateTimeDb, useRouter } from '@core/utils/utils'
import { onMounted, ref } from '@vue/composition-api'
import Swal from 'sweetalert2'
import { useMasterFilter, useMerchantDetail } from './useMerchant'

export default {
  name: 'EditAccount',
  components: {
    BCard,
    BCardText,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BInputGroup,
    BFormCheckbox,
    BButton,
    BFormSelect,
    vSelect,
  },
  setup() {
    const { route } = useRouter()
    const merchantId = route.value.params.id

    const {
      userStatus,
      userStatusOptions,
    } = useMasterFilter()

    const {
      merchant,
      payInConfig,
      payOutConfig,
      viettelConfig,
      vinaConfig,
      mobiConfig,
      vietnamobileConfig,
      momoQrConfig,
      zaloQrConfig,
      viettelQrConfig,
      usdtConfig,
      fetchMerchantDetail,
      getConfigByName,
      updateMerchant,
    } = useMerchantDetail()

    const controlStatusOptions = ref([
      { value: 'default', text: 'Mặc định' },
      { value: 'min', text: 'Gía trị nhỏ nhất' },
      { value: 'half', text: 'Trừ 50%' },
    ])

    const memberFeeConfigs = ref({
      is_active: false,
      member_fee_qr: 0,
      member_fee_viettel: 0,
      member_fee_vinaphone: 0,
      member_fee_mobi: 0,
      member_fee_vnm: 0,
    })

    const handleSaveConfig = () => {
      updateMerchant({
        merchantId,
        feeConfig: [
          payInConfig.value,
          payOutConfig.value,
          viettelConfig.value,
          vinaConfig.value,
          mobiConfig.value,
          vietnamobileConfig.value,
          momoQrConfig.value,
          zaloQrConfig.value,
          viettelQrConfig.value,
          usdtConfig.value,
        ],
        status: userStatus.value,
        forControlCard: merchant.value.forControlCard,
        ips: merchant.value.ips,
        payoutLimit: merchant.value.payoutLimit,
        member_fee_config: memberFeeConfigs.value,
        usdt_out_fee: merchant.value.usdtOutFee,
      })
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Thành công',
            text: 'Cập nhật thành công',
          })
        })
        .catch(() => {
          Swal.fire({
            icon: 'error',
            title: 'Thất bại',
            text: 'Cập nhật thất bại',
          })
        })
    }

    onMounted(() => {
      fetchMerchantDetail({ merchantId })
        .then(responseData => {
          merchant.value = responseData.data
          userStatus.value = responseData.data.status

          const { feeConfig, memberFeeConfig } = responseData.data
          payInConfig.value = getConfigByName('payin', feeConfig)
          payOutConfig.value = getConfigByName('payout', feeConfig)
          viettelConfig.value = getConfigByName('viettel', feeConfig)
          vinaConfig.value = getConfigByName('vinaphone', feeConfig)
          mobiConfig.value = getConfigByName('mobifone', feeConfig)
          vietnamobileConfig.value = getConfigByName('vietnamobile', feeConfig)
          momoQrConfig.value = getConfigByName('momo_qr', feeConfig)
          zaloQrConfig.value = getConfigByName('zalo_qr', feeConfig)
          viettelQrConfig.value = getConfigByName('viettel_qr', feeConfig)
          usdtConfig.value = getConfigByName('usdt', feeConfig)
          if (memberFeeConfig) {
            memberFeeConfigs.value = memberFeeConfig
          }
        })
    })

    return {
      userStatus,
      userStatusOptions,
      merchant,
      payInConfig,
      payOutConfig,
      viettelConfig,
      vinaConfig,
      mobiConfig,
      vietnamobileConfig,
      controlStatusOptions,
      momoQrConfig,
      zaloQrConfig,
      viettelQrConfig,
      usdtConfig,
      memberFeeConfigs,

      formatDateTimeDb,
      handleSaveConfig,
    }
  },
  computed: {
    mcCreatedAt() {
      return formatDateTimeDb(this.merchant.createdAt)
    },
  },
}
</script>

<style scoped>
#mc-status {
  min-width: 150px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
#app .vs__selected-options {
  flex-wrap: nowrap;
  max-width: calc(100% - 24px); /* change this to `- 40px` if you're supporting a `clearable` field; I was not */
}

#app .vs__selected {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}
</style>
